import React from 'react';
import Layout from '../components/Layout';
import styled from 'styled-components';
import { Link } from 'gatsby';
import colors from '../constants/colors';

const CenterIt = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;

  p {
    color: ${colors.burgund};
    text-decoration: underline solid;
    text-decoration-color: ${colors.burgund};
    text-align: center;
  }

  h1 {
    text-align: center;
  }
`;

export default class NoPageHere extends React.Component {
  render() {
    return (
      <Layout title="404 | Gau Alt-Burgund" description="Seite nicht gefunden!">
        <CenterIt>
          <h1>Diese Seite wurde nicht gefunden.</h1>
          <Link to={'/'}>
            <p>zurück zur Startseite</p>
          </Link>
        </CenterIt>
      </Layout>
    );
  }
}
